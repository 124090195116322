export const ADMIN_USER = 'dek'
export const ADMIN_PASS = 'dekstone je nejlepsi'

/** URL aplikace pro nastavení CORS. */
export const APP_URL = 'https://rezervace.dekstone.cz'
export const API_URL = 'https://api.rezervace.dekstone.cz'

/** Klíč session */
export const SESSION_SECRET = "cats are not welcome here"

/** Port, na kterém naslouchá API (express) */
export const API_PORT = process.env.PORT

export const DB_HOST: string = process.env.DB_HOST ?? 'localhost'
export const DB_PORT: number = process.env.DB_PORT ? +process.env.DB_PORT : 3306
export const DB_USER: string = process.env.DB_USER ?? ''
export const DB_PASS: string = process.env.DB_PASS ?? ''
export const DB_NAME: string = process.env.DB_NAME ?? ''
export const DB_SYNC = !!process.env.DB_SYNC

export const MAIL_MAIN = 'info@dekstone.cz'
export const MAIL_FROM = 'web@dekstone.cz'
export const MAIL_TRANSPORTER_OPTIONS = {
    host: "stonemail.dekstone.cz",
    port: 465,
    secure: true,
    auth: {
        user: "web@dekstone.cz",
        pass: "DKdUV6fRgtcGC",
    }
}

export const MAIL_BOOKING_INFO = [
    MAIL_MAIN,
    'odbyt@dekstone.cz'
]

/** JWT secret pro tiché přihlášení */
export const JWT_SECRET = 'sdsds'
