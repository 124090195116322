import * as t from "io-ts"

export function resourceToLocation<T>(resource: ResourceType<T>): string {
    return "/api/" + resource.etype
}

export const eventsLocation = '/api/events'

/**
 * Abychom mohli používat generika v rámci "prototype fáze".
 * Pokud chceme ověřovat compile-time pouze typ, stačilo by nám T.
 * Pokud chceme zapsat, že z typu budeme vytvářet objekt, musíme takto.
 */
export declare type ResourceType<T> = {
    etype: string;
    guard: t.TypeC<any>;
    new(...args: any[]): T; // Říká, že nad typem můžeme zavolat new T(...cokoliv)
};
