import { createStore, Payload } from 'vuex';
import { v4 as uuidv4 } from 'uuid'
import * as t from "io-ts"
import * as td from "io-ts-types"
import { pipe } from 'fp-ts/function'
import { fold } from 'fp-ts/Either'

const StateTypeGuard = t.type({
  isLoggedIn: t.boolean,
  filteredSalesmen: t.array(t.string),
  token: t.string,
  updated: t.union([td.DateFromISOString, t.undefined, t.null]),
})

type StateType = t.TypeOf<typeof StateTypeGuard>

export class logInActionPayload implements Payload {

  type = 'log'

  constructor(
    public isLoggedIn = false,
  ) { }

}

export class FilterSalesmenPayload implements Payload {
  type = 'filterSalesmen'

  constructor(
    public filterSalesmen: string[] = []
  ) { }
}

function createInitialState(): StateType {
  return {
    isLoggedIn: false,
    filteredSalesmen: [],
    token: uuidv4(),
    updated: null,
  }
}

const LOCAL_STORAGE_STORE_KEY = 'store';

function getOrCreateInitialState(): StateType {
  const asString = localStorage.getItem(LOCAL_STORAGE_STORE_KEY)
  if (asString) {
    const unsafeState = JSON.parse(asString)

    return pipe(
      StateTypeGuard.decode(unsafeState),
      fold(
        () => createInitialState(), // Při chybě state znovu vytvoříme.
        (safeStore) => safeStore // Jinak vrátíme state v bezpečném stavu.
      )
    )
  }

  return createInitialState()
}

export const store = createStore<StateType>({
  state: getOrCreateInitialState(),
  mutations: {
    log: (state, payload: logInActionPayload) => {
      state.isLoggedIn = payload.isLoggedIn
    },
    filterSalesmen: (state, payload: FilterSalesmenPayload) => {
      state.filteredSalesmen = payload.filterSalesmen
    },
    update: (state) => state.updated = new Date,
  },
  actions: {
    log: (context, payload: logInActionPayload) => {
      context.commit(payload)
    },
    filterSalesmen: (context, payload: FilterSalesmenPayload) => {
      context.commit(payload)
    }
  },
  modules: {
  },
})

store.subscribe((mutation, state) => {
  localStorage.setItem(LOCAL_STORAGE_STORE_KEY, JSON.stringify(StateTypeGuard.encode(state)))
})

// Výchozí stav není do localStore uložen, dokud neproběhne první commit / dispatch.
// Jelikož potřebujeme mít hned stabilní uuid, provedeme jinak zbytečný commit mutující property updated.
store.commit('update')

export const getStore = () => { return store }

export default store