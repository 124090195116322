import * as t from "io-ts"
import { pipe } from 'fp-ts/function'
import { fold } from 'fp-ts/Either'

/**
 * Z guardu a json hodnoty vrací buď objekt validního typu, nebo null.
 * Použijeme například u API, kdy se nelze spolehnout na vstupní data.
 */
export function jsonToType<P extends t.Props>(guard: t.TypeC<P>, value: unknown): t.TypeOf<typeof guard> | null {
    return pipe(
        guard.decode(value),
        fold(
            () => null,
            (query) => query
        )
    )
}
