
import { defineComponent } from "vue";
import { getStore } from "./store";

export default defineComponent({
  setup() {
    document.title = "Dekstone rezervace";

    return {
      token: getStore().state.token
    }
  },
});
