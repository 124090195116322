// Zapouzřuje klientská volání na API.

import axios, { AxiosResponse } from "axios"
import { Appointment, AppointmentBookingGuard, AppointmentReservationGuard, AppointmentType, ClientType, Goods, Salesman } from "@model/domain"
import { resourceToLocation, ResourceType } from "@api/conventions"
import { getStore, logInActionPayload } from "@/store"
import { rejects } from "assert"
import { ListAllAppointmentsRequestQueryGuard, ListAppointmentsRequestQueryGuard } from "@api/appointment/types"
import * as config from "@/../config"
import { jsonToType } from "@api/validation"
import { RenameSalesmanRequestQueryGuard } from "@api/salesman/types"
import { CancelAppointmentQueryGuard } from "@api/appointment/cancelTypes"

const baseUri = config.API_URL

axios.defaults.withCredentials = true // Automaticky předávat cookies

const handleError = (reject: (reason: any) => void) => (reason: any) => {
    console.error(reason)
    if (reason.response) {
        const response: AxiosResponse = reason.response
        if (response.status === 403) {
            getStore().dispatch(new logInActionPayload(false))
        }
    }
    reject(reason)
}

export function login(user: { username: string, password: string }): Promise<any> {
    return new Promise((resolve, reject) => {
        const url = baseUri + "/api/authenticate"
        const formData = new URLSearchParams()
        formData.append('username', user.username)
        formData.append('password', user.password)

        axios.post(url, formData)
            .then(() => resolve(true))
            .catch(handleError(reject))
    })
}

export function loadGoods(): Promise<Goods[]> {
    return new Promise((resolve, reject) => {
        list(Goods).then(goods => resolve(goods)).catch(e => reject(e))
    })
}

export function loadSalesmen(): Promise<Salesman[]> {
    return new Promise((resolve, reject) => {
        list(Salesman).then(salesmen => resolve(salesmen)).catch(e => reject(e))
    })
}

export function loadAppointments(from: Date, to: Date, by: string): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
        const query = ListAppointmentsRequestQueryGuard.encode({ from, to, by })
        list(Appointment, query).then(appointments => resolve(appointments)).catch(e => reject(e))
    })
}

export function loadAllAppointments(from: Date, to: Date): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
        const query = ListAllAppointmentsRequestQueryGuard.encode({ from, to })
        list(Appointment, query, '/all').then(appointments => resolve(appointments)).catch(e => reject(e))
    })
}

export function putGoods(goods: string): Promise<Goods> {
    return new Promise((resolve, reject) => {
        put(Goods, new Goods(goods)).then(result => resolve(result))
    })
}

export function delGoods(goods: Goods): Promise<Goods> {
    return new Promise((resolve, reject) => {
        del(Goods, goods).then(result => resolve(result))
    })
}

export function putSalesman(salesman: Salesman): Promise<Salesman> {
    return new Promise((resolve, reject) => {
        put(Salesman, salesman).then(result => resolve(result))
    })
}

export function renameSalesman(salesman: Salesman, newName: string): Promise<any> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Salesman) + "/rename"
        const rename = RenameSalesmanRequestQueryGuard.encode({ from: salesman.name, to: newName })
        console.log(rename)
        axios.post(url, rename)
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}

export function putAppointment(appointment: Appointment): Promise<Appointment> {
    return new Promise((resolve, reject) => {
        put(Appointment, appointment).then(result => resolve(result))
    })
}

export function delSalesman(salesman: Salesman): Promise<Salesman> {
    return new Promise((resolve, reject) => {
        del(Salesman, salesman).then(result => resolve(result)).catch(error => reject(error))
    })
}

export function delAppointment(appointment: Appointment): Promise<Appointment> {
    return new Promise((resolve, reject) => {
        del(Appointment, appointment).then(result => resolve(result))
    })
}

export function generateAppointments(from: Date, fromTime: number, to: Date, toTime: number, salesmen: Salesman[]): Promise<Appointment[]> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/generate"
        axios.post(url, { from, fromTime, to, toTime, salesmen })
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}

export function reserveAppointment(appointment: Appointment, token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/reserve"
        console.log(appointment)
        const reservation = AppointmentReservationGuard.encode({appointment, by: token})
        axios.post(url, reservation)
            .then(response => resolve(true))
            .catch(handleError(reject))
    })
}

export function freeAppointment(appointment: Appointment, token: string) {
    console.log("free")
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/free"
        const reservation = AppointmentReservationGuard.encode({appointment, by: token})
        axios.post(url, reservation)
            .then(response => resolve(true))
            .catch(handleError(reject))
    })
}

export function bookAppointment(appointment: AppointmentType, client: ClientType): Promise<{ status: string, appointment: Appointment }> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/book"
        const appointmentBooking = AppointmentBookingGuard.encode({appointment, client})
        axios.post(url, appointmentBooking)
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}

export function cancelAppointment(appointment: AppointmentType): Promise<{ status: string, appointment: Appointment }> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/cancel"
        const cancelAppointmentQuery = CancelAppointmentQueryGuard.encode({appointmentId: appointment.id})
        axios.post(url, cancelAppointmentQuery)
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}

export function cancelAppointmentByClient(appointmentId: string, hash: string): Promise<{ status: 'success'|'failure' }> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(Appointment) + "/cancel/" + appointmentId + "/" + hash
        axios.get(url)
            .then(() => resolve({status: 'success'}))
            .catch(() => resolve({status: 'failure'}))
    })
}

function list<T>(resource: ResourceType<T>, params?: any, suffix?: string): Promise<T[]> {
    return new Promise((resolve) => {
        let url = baseUri + resourceToLocation(resource)
        if (suffix) url += suffix
        axios.get<T[]>(url, { params })
            .then(response => {
                const entities: T[] = []
                response.data.reduce((heap, one) => {
                    const validated = jsonToType(resource.guard, one)
                    if (validated) {
                        const classed = Object.assign(new resource(), validated)
                        heap.push(classed)
                    }
                    return heap
                }, entities)

                console.log(entities)

                resolve(entities)
            })
            .catch(handleError(rejects))
    })
}

function put<T>(resource: ResourceType<T>, entity: T): Promise<T> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(resource)
        axios.put<T>(url, entity)
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}

function del<T>(resource: ResourceType<T>, entity: T): Promise<T> {
    return new Promise((resolve, reject) => {
        const url = baseUri + resourceToLocation(resource)
        axios.delete<T>(url, { data: entity })
            .then(response => resolve(response.data))
            .catch(handleError(reject))
    })
}