import * as t from "io-ts"
import * as td from "io-ts-types"

export const ListAppointmentsRequestQueryGuard = t.type({
    by: t.string,
    from: td.DateFromISOString,
    to: td.DateFromISOString,
})

export type ListAppointmentsRequestQueryType = t.TypeOf<typeof ListAppointmentsRequestQueryGuard>

export const ListAllAppointmentsRequestQueryGuard = t.type({
    from: td.DateFromISOString,
    to: td.DateFromISOString,
})

export type ListAllAppointmentsRequestQueryType = t.TypeOf<typeof ListAllAppointmentsRequestQueryGuard>
