/**
 * Popis domény
 * 
 * Prodejce rozumí množině sortimentu.
 * Prodejce má v databázi časové sloty, kdy je dostupný pro konzultace.
 * 
 * Klient vybírá sortiment, se kterým chce poradit.
 * Systém mu zobrazí možné termíny nezávisle na dostupném prodejci. Tj. prodejci 
 * jsou klientovi skryti, přiřazení je náhodné. 
 */

import { Column, Entity, Index, JoinColumn, JoinTable, ManyToMany, ManyToOne, PrimaryColumn, Unique } from "typeorm"
import { v4 as uuidv4 } from 'uuid'
import * as t from "io-ts"
import * as td from "io-ts-types"

const GoodsGuard = t.type({
    id: t.string,
})

type GoodsType = t.TypeOf<typeof GoodsGuard>

@Entity()
export class Goods implements GoodsType {

    static etype = 'goods'

    static guard = GoodsGuard

    @PrimaryColumn()
    id: string

    constructor(
        id: string
    ) {
        this.id = id
    }
}

export const SalesmanGuard = t.type({
    name: t.string,
    email: t.union([t.null, t.string]),
    goods: t.array(GoodsGuard)
})

export type SalesmanType = t.TypeOf<typeof SalesmanGuard>

@Entity()
export class Salesman implements SalesmanType {

    static etype = 'salesman'

    static guard = SalesmanGuard

    @PrimaryColumn()
    public name: string

    @Column({type: String, nullable: true})
    public email = null
    
    @ManyToMany(type => Goods, {eager: true})
    @JoinTable()
    goods: Goods[]

    constructor(
        name: string,
        goods: Goods[],
    ) {
        this.name = name
        this.goods = goods
    }

    understandsGoods(goods: Goods): boolean {
        return this.goods.some((one) => one.id === goods.id)
    }
}

export const AppointmentGuard = t.type({
    id: t.string,
    from: td.DateFromISOString,
    to: td.DateFromISOString,
    salesman: SalesmanGuard,
    bookedByName: t.union([t.string, t.null]),
    bookedByEmail: t.union([t.string, t.null]),
    bookedByPhone: t.union([t.string, t.null]),
    bookedByNote: t.union([t.string, t.null]),
    reservedBy: t.union([t.string, t.null]),
    reservedUntil: t.union([td.DateFromISOString, t.null]),
})

export type AppointmentType = t.TypeOf<typeof AppointmentGuard>

@Entity()
@Unique('unique_appointment', ['from', 'to', 'salesman'])
export class Appointment implements AppointmentType {

    static etype = 'appointment'

    static guard = AppointmentGuard

    @PrimaryColumn()
    id: string

    @Index()
    @Column({type: Date})
    from: Date

    @Index()
    @Column({type: Date})
    to: Date
    
    @ManyToOne(type => Salesman, {eager: true, onUpdate: 'CASCADE'})
    @JoinColumn({name: 'salesman'})
    salesman: Salesman
    
    @Index()
    @Column({type: String, nullable: true})
    bookedByName: string|null = null

    @Index()
    @Column({type: String, nullable: true})
    bookedByEmail: string|null = null

    @Column({type: String, nullable: true})
    bookedByPhone: string|null = null

    @Column({type: String, nullable: true})
    bookedByNote: string|null = null

    @Index()
    @Column({type: String, nullable: true})
    reservedBy: string|null = null

    @Index()
    @Column({type: 'datetime', nullable: true})
    reservedUntil: Date|null = null

    constructor(
        from: Date,
        to: Date,
        salesman: Salesman
    ) {
        this.id = uuidv4()
        this.from = from
        this.to = to
        this.salesman = salesman
    }

    canReserve(by: string): boolean {
        if (this.reservedUntil === null) return true
        if (this.reservedUntil < new Date) return true
        if (this.reservedBy === by) return true

        return false
    }

    cancel(): void {
        this.bookedByEmail = null
        this.bookedByName = null
        this.bookedByNote = null
        this.bookedByPhone = null
        this.reservedBy = null
        this.reservedUntil = null
    }

}

export const ClientGuard = t.type({
    name: t.string,
    email: t.string, // TODO proper email regex check
    phone: t.string,
    note: t.string,
})

export type ClientType = t.TypeOf<typeof ClientGuard>

export const AppointmentBookingGuard = t.type({
    appointment: AppointmentGuard,
    client: ClientGuard,
});

export type AppointmentBookingType = t.TypeOf<typeof AppointmentBookingGuard>

export const AppointmentReservationGuard = t.type({
    appointment: AppointmentGuard,
    by: t.string,
})

export type AppointmentReservationType = t.TypeOf<typeof AppointmentReservationGuard>