import { Appointment, Goods } from "../../model/domain"
import { EventInput } from "@fullcalendar/common"
import * as facade from '@/model/client'

export interface ReservationOption {
    from: Date
    to: Date
    availability: number
    goods: Goods,
    appointment: Appointment,
}

export const splice: (appointments: Appointment[]) => ReservationOption[] = function (appointments) {
    const map: Map<string, ReservationOption> = new Map()

    appointments.forEach((appointment) => {
        appointment.salesman.goods.forEach((specificGoods) => {
            const key = appointment.from.toString() + "-" + appointment.to.toString() + "-" + specificGoods.id

            const reservationOption = map.get(key)
            if (reservationOption) reservationOption.availability++
            else map.set(key, {
                from: appointment.from,
                to: appointment.to,
                availability: 1,
                goods: specificGoods,
                appointment
            })
        })
    })

    return Array.from(map.values())
}

export const loadEvents = (from: Date, to: Date, by: string, selectedGoods: Goods | null): Promise<EventInput[]> => {
    return new Promise((resolve, reject) => {
        facade.loadAppointments(from, to, by).then((appointments) => {
            console.log(appointments)
            let reservationOptions = splice(appointments)

            if (selectedGoods !== null) {
                reservationOptions = reservationOptions.filter((reservationOption) => reservationOption.goods.id === selectedGoods.id)
            }

            const events: EventInput[] = reservationOptions.map((reservationOption) => {
                const canReserve = reservationOption.appointment.canReserve(by)
                return {
                    start: reservationOption.from,
                    end: reservationOption.to,
                    title: "Volné termíny: " + reservationOption.availability.toString(),
                    backgroundColor: canReserve ? 'green' : 'red',
                    extendedProps: {
                        appointment: reservationOption.appointment
                    }
                }
            })

            console.log(events)

            setTimeout(() => { // TODO timeout je debug only
                resolve(events)
            }, 100)
        }).catch((error) => {
            console.error(error)
            reject("Could not load appointments")
        })
    })
}
