// Event Source Server: Klientská strana
// Naslouchá na http streamu na emitované eventy předává jako rxjs Subject, na který mohou jednotlivé komponenty naslouchat.
// CAVE! Pokud se ve Vue komponentě přihlásíme na stream, je potřeba implmentovat onUnmounted(), kde provedeme odhlášení. Jinak vzniká v prohlížeči memory leak!

import { eventsLocation } from "@api/conventions";
import { Subject } from "rxjs";
import * as config from "@/../config"

export const eventListener = new Subject()

const ess = new EventSource(config.API_URL + eventsLocation) // TODO: Move to config!
ess.onopen = () => console.log("ESS connection opened")
ess.onmessage = (event) => eventListener.next(event.data)
ess.onerror = (err) => console.log(err)
