
import "@fullcalendar/core/vdom"; // solves problem with Vite
import { Calendar, CalendarOptions, EventApi } from "@fullcalendar/core";
import csLocale from '@fullcalendar/core/locales/cs';
import FullCalendarComponent from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { ClientType, Goods } from "@model/domain";
import {
  defineComponent,
  onUnmounted,
  ref,
  Ref,
  watchEffect,
} from "@vue/runtime-core";
import * as facade from "../model/client";
import { bookAppointment } from "../model/client";
import { useDisplay } from "vuetify";
import { eventListener } from "../model/ess";
import { loadEvents } from "@/model/reserve";
import { getStore } from "@/store";

export default defineComponent({
  components: {
    FullCalendarComponent,
  },
  setup() {
    const store = getStore();
    const display = useDisplay();

    const fullCalendar: Ref<{ getApi(): Calendar } | null> = ref(null);
    const loading: Ref<HTMLDivElement | null> = ref(null);
    const goods: Ref<Goods[]> = ref([]);

    const selectedGoods: Ref<Goods | null> = ref(null);
    const selectedEvent: Ref<EventApi | null> = ref(null);

    const hasBeenBooked = ref(false);

    const selectGoods: (goods: Goods) => void = function (goods) {
      selectedGoods.value = goods;
    };

    const client: Ref<ClientType> = ref({
      name: "",
      email: "",
      phone: "",
      note: "",
    });

    const book = (event: EventApi): void => {
      const appointment = event.extendedProps.appointment;
      bookAppointment(appointment, client.value).then(() => {
        client.value.name = "";
        client.value.email = "";
        client.value.phone = ""
        client.value.note = ""
        hasBeenBooked.value = true
      });
    };

    const calendarOptions: CalendarOptions = {
      plugins: [timeGridPlugin, listPlugin, interactionPlugin],
      locale: csLocale,
      weekends: false,
      allDaySlot: false,
      displayEventEnd: false,
      events: (info, success) => {
        loadEvents(
          info.start,
          info.end,
          store.state.token,
          selectedGoods.value
        ).then((events) => success(events));
      },
      eventClick: (info) => {
        const token = store.state.token;
        facade
          .reserveAppointment(info.event.extendedProps.appointment, token)
          .then(() => {
            selectedEvent.value = info.event;
            hasBeenBooked.value = false
            reserveDialog.value = true;
          })
          .catch((error) => console.error(error)); // TODO Proper display of error to user
      },
      loading: (isLoading) => {
        const loadingValue = loading.value;
        if (loadingValue !== null) {
          loadingValue.style.display = isLoading ? "block" : "none";
        }
      },
      slotMinTime: "06:00:00", // TODO derivivat z eventů?
      slotMaxTime: "18:00:00", // TODO derivivat z eventů?
      expandRows: true,
      height: "auto",
    };

    const freeAppointment = () => {
      console.log("free called")
      facade
        .freeAppointment(
          selectedEvent.value?.extendedProps.appointment,
          store.state.token
        )
        .then(() => {
          selectedEvent.value = null;
          reserveDialog.value = false;
        })
        .catch((error) => {
          console.error(error);
          selectedEvent.value = null;
          reserveDialog.value = false;
        }); // TODO Proper display of error to user
    };

    watchEffect(() => {
      if (fullCalendar.value) {
        fullCalendar.value
          .getApi()
          .changeView(
            window.innerWidth < 600 ? "timeGridDay" : "timeGridWeek"
          );
      }
    });

    const eventListenerSubscription = eventListener.subscribe(() => {
      if (fullCalendar.value) {
        fullCalendar.value.getApi().refetchEvents();
      }
    });

    onUnmounted(() => eventListenerSubscription.unsubscribe());

    function reloadGoods() {
      facade.loadGoods().then((loadedGoods) => (goods.value = loadedGoods));
    }

    reloadGoods();

    const delGoodsExecute = (goods: Goods) => {
      facade.delGoods(goods).then(() => reloadGoods());
    };

    const reserveDialog: Ref<boolean> = ref(false);

    return {
      fullCalendar,
      loading,
      calendarOptions,
      goods,
      selectedGoods,
      selectedEvent,
      selectGoods,
      book,
      freeAppointment,
      delGoodsExecute,
      reserveDialog,
      hasBeenBooked,
      display,
      client,
    };
  },
});
