import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Reserve from '@/views/Reserve.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'reserve',
    component: Reserve,
  },
  {
    path: '/cancel/:appointmentId/:hash',
    component: () => import('@/cancel/Cancel.vue'),
    props: true,
  },
  {
    path: '/admin',
    component: () => import('../admin/Admin.vue'),
    children: [
      {
        path: 'salesmen',
        name: 'adminSalesmen',
        component: () => import('@/admin/Salesmen.vue'),
      },
      {
        path: 'appointments',
        name: 'adminAppointments',
        component: () => import('@/admin/Appointments.vue'),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
